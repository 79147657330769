<template>
  <div>
    <VariablesField
      v-if="isVariablesField"
      v-model="value"
      :field="field"
      :field-wrapper="true"
      :show-toggle="true"
      @toggle-variables-field="toggleVariablesField"
    />
    <!------------ START: FieldWrapper ------------>
    <component
      :is="field.plain ? 'div' : 'FieldWrapper'"
      v-else
      :field="field"
      @toggle-variables-field="toggleVariablesField"
    >
      <div
        ref="fullScreen"
        class="code-editor-wrapper position-relative"
        :class="{ fullscreen: fullScreen }"
        @keyup.esc="fullScreen = false"
      >
        <!------------ START: JSON structure ------------>
        <JsonStructure
          v-if="showJsonStructure"
          :data="$v.value.$model"
          :prefix="field.jsonPathPrefix"
          :suffix="field.jsonPathSuffix"
        />
        <!------------ END: JSON structure ------------>
        <!------------ START: Code editor ------------>
        <CodeMirror
          v-else
          ref="codemirror"
          v-model="$v.value.$model"
          :options="editorOptions"
          style="cursor: text"
        />
        <!------------ END: Code editor ------------>
        <!------------ START: Overlay buttons ------------>
        <div class="overlay-buttons">
          <!------------ START: JSON structure view button ------------>
          <div
            v-if="editorOptions.mode === modes.json"
            class="btn btn-transparent-primary btn-icon btn-circle btn-sm btn-clean"
            @click="showJsonStructure = !showJsonStructure"
          >
            <i class="fal fa-brackets-curly" />
          </div>
          <!------------ END: JSON structure view button ------------>
          <!------------ START: Copy button ------------>
          <div
            class="btn btn-transparent-primary btn-icon btn-circle btn-sm btn-clean"
            @click="copyValue"
          >
            <i class="fal fa-copy" />
          </div>
          <!------------ END: Copy button ------------>
          <!------------ START: Full screen button ------------>
          <div
            class="btn btn-transparent-primary btn-icon btn-circle btn-sm btn-clean ml-1"
            @click="fullScreen = !fullScreen"
          >
            <i class="fal fa-expand" />
          </div>
          <!------------ START: Full screen button ------------>
        </div>
        <!------------ END : Overlay buttons ------------>
      </div>
      <!------------ START: Alerts ------------>
      <div class="code-alert">
        <!------------ START: Invalid JSON alert ------------>
        <Alert
          v-if="!validJson"
          :field="alerts.invalidJson"
          class="mt-1"
          default-value=""
        />
        <!------------ END: Invalid JSON alert ------------>
      </div>
      <!------------ END: Alerts ------------>
    </component>
    <!------------ END: FieldWrapper ------------>
  </div>
</template>

<script>
import {
  base,
  eagerValidation,
  variablesField
} from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import Alert from "@/components/Tools/FormHelper/Fields/Alert.vue";
import JsonStructure from "@/components/Tools/FormHelper/Components/JsonStructure";

import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/fold/foldgutter.css";
import "codemirror/addon/fold/foldcode.js";
import "codemirror/addon/fold/foldgutter.js";
import "codemirror/addon/fold/brace-fold.js";
import "codemirror/addon/fold/indent-fold.js";
import "codemirror/addon/fold/comment-fold.js";
import "codemirror/theme/material.css";

/********** Languages **********/
import "codemirror/mode/php/php";
import "codemirror/mode/javascript/javascript";
import "codemirror/mode/yaml/yaml";
import "codemirror/mode/sql/sql";
import "codemirror/mode/twig/twig";
import VariablesField from "@/components/Tools/FormHelper/Components/InputVariables.vue";
// IMPORTANT: When adding language, add option to modes below and !update docs!

export default {
  components: {
    VariablesField,
    Alert,
    FieldWrapper,
    CodeMirror: codemirror,
    JsonStructure
  },
  mixins: [base, variablesField, eagerValidation],
  props: {},
  data() {
    return {
      fullScreen: false,
      editorOptions: {
        tabSize: this.field.tabSize ?? 4,
        theme: "material",
        mode: "",
        lineNumbers: true,
        line: true,
        readOnly: false,
        lineWrapping: true,
        matchBrackets: true,
        foldGutter: true,
        gutters: ["CodeMirror-linenumbers", "CodeMirror-foldgutter"]
      },
      modes: {
        php: "text/x-php",
        js: "text/javascript",
        json: "application/json",
        yaml: "text/x-yaml",
        sql: "text/sql",
        twig: "text/x-twig"
      },
      alerts: {
        invalidJson: {
          label: this.$t("formHelper.errors.json"),
          icon: "fal fa-circle-exclamation",
          color: "danger",
          style: "light",
          dense: true
        }
      },
      showJsonStructure: false
    };
  },
  computed: {
    validJson: function () {
      if (this.field.lang !== "json" || !this.value.length) {
        return true;
      }
      try {
        JSON.parse(this.value);
        return true;
      } catch (e) {
        return false;
      }
    }
  },
  watch: {
    // Watch fullScreen prop
    fullScreen: function () {
      if (this.fullScreen) {
        // If fullScreen is true, request to open editor in fullscreen
        this.$refs.fullScreen.requestFullscreen();
      } else {
        // Else exit fullscreen
        document.exitFullscreen();
      }
    },
    isDisabled: function () {
      this.setReadonly();
    }
  },
  mounted() {
    this.editorOptions.mode = this.modes[this.field.lang ?? "php"];
    this.setReadonly();
  },
  methods: {
    setReadonly() {
      // Update disable state of editor
      this.$set(this.editorOptions, "readOnly", this.isDisabled);
    },
    toggleVariablesField() {
      this.isVariablesField = !this.isVariablesField;
      // Set value to either empty string (if variables component gets activated)
      // or field's default value (if variables component gets deactivated)
      this.value = "";
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.CodeMirror) {
  border-radius: 6px;
  padding: 5px 0;
  line-break: anywhere;
  margin: 0.5rem 1rem 2rem 1rem;
  .fullscreen & {
    height: 100vh;
  }
}

:deep(.overlay-buttons) {
  display: none !important;
  position: absolute;
  top: 20px;
  right: 20px;
  .code-editor-wrapper:hover & {
    display: block !important;
  }
}
</style>
