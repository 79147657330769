<template>
  <div class="json-structure py-3">
    <!------------ START: Title ------------>
    <div class="mb-1">{{ $t("formHelper.jsonStructure.title") }}:</div>
    <!------------ END: Title ------------>
    <!------------ START: Render fields ------------>
    <div v-if="structure.length">
      <JsonStructureField
        v-for="field in structure"
        :key="field.name"
        :field="field"
        @copy-path="copyPath"
      />
    </div>
    <!------------ END: Render fields ------------>
    <!------------ START: Invalid message ------------>
    <div v-else>{{ $t("formHelper.jsonStructure.invalid") }}</div>
    <!------------ END: Invalid message ------------>
  </div>
</template>

<script>
import _ from "lodash";
import JsonStructureField from "@/components/Tools/FormHelper/Components/JsonStructureField.vue";
import { copyToClipboard } from "@/components/Tools/helperFunctions";

export default {
  components: { JsonStructureField },
  props: {
    data: {
      type: String,
      default: "{}"
    },
    prefix: {
      type: String,
      default: ""
    },
    suffix: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      structure: []
    };
  },
  watch: {
    data() {
      this.getJsonStructure();
    }
  },
  mounted() {
    this.getJsonStructure();
  },
  methods: {
    getJsonStructure() {
      let json;
      try {
        // Try to parse json
        json = JSON.parse(this.data);
      } catch (e) {
        // If invalid json, set empty
        json = {};
      }
      // Return if empty
      if (_.isEmpty(json)) {
        return {};
      }
      // If value is array, only use first entry for performance reasons
      // with asterisk as index placeholder
      if (Array.isArray(json)) {
        json = { "*": json[0] };
      }
      this.structure = this.parseStructure(json);
    },
    parseStructure(json, parent = "") {
      return Object.keys(json).map(key => {
        // Set children to null by default and set full name
        let children = null;
        let fullName = parent ? `${parent}.${key}` : key;
        // If value is of type object, parse children
        if (typeof json[key] === "object" && json[key] !== null) {
          let childrenRaw = json[key];
          // If value is array, only use first entry for performance reasons
          // with asterisk as index placeholder
          if (Array.isArray(json[key])) {
            childrenRaw = { "*": json[key][0] };
          }
          // Parse children's structures
          children = this.parseStructure(childrenRaw, fullName);
        }
        return {
          name: key,
          fullName: fullName,
          type: this.getValueType(json[key]),
          children: children
        };
      });
    },
    getValueType(value) {
      let type = typeof value;
      if (value === null) {
        type = "unknown";
      } else if (type === "object") {
        if (Array.isArray(value)) {
          type = "array";
        } else {
          type = "object";
        }
      }
      return type;
    },
    copyPath(path) {
      let prefix = "",
        suffix = "";
      if (this.prefix) {
        prefix = "{{" + this.prefix + ".";
      } else if (this.suffix) {
        prefix = "{{";
      }
      if (this.suffix) {
        suffix = "." + this.suffix + "}}";
      } else if (this.prefix) {
        suffix = "}}";
      }
      copyToClipboard(path, prefix, suffix);
    }
  }
};
</script>

<style lang="scss" scoped>
.json-structure {
  background-color: #263238;
  color: #eeffff;
  font-family: monospace;
  border-radius: 6px;
  margin: 6px 13px 0 13px;
  padding-left: 38px;
}
</style>
