<template>
  <div class="json-structure-field">
    <!------------ START: Collapse button / space ------------>
    <span class="mr-1">
      <i
        v-if="field.children"
        class="cursor-pointer collapse-button"
        :class="[isCollapsed ? 'fal fa-caret-right' : 'fal fa-caret-down']"
        @click="isCollapsed = !isCollapsed"
      />
      <span v-else class="mr-3"></span>
    </span>
    <!------------ END: Collapse button / space ------------>
    <!------------ START: Field name ------------>
    <span class="cursor-pointer" @click="copyPath(field.fullName)"
      >{{ field.name }}:</span
    >
    <!------------ END: Field name ------------>
    <!------------ START: Field type ------------>
    <span class="font-italic" :style="typeColor">
      {{ $t("formHelper.jsonStructure." + field.type) }}
    </span>
    <!------------ END: Field type ------------>
    <!------------ START: Children ------------>
    <div v-if="field.children && !isCollapsed" class="ml-5">
      <JsonStructureField
        v-for="child in field.children"
        :key="child.name"
        :field="child"
        @copy-path="copyPath"
      />
    </div>
    <!------------ END: Children ------------>
  </div>
</template>

<script>
export default {
  components: {
    JsonStructureField: () =>
      import("@/components/Tools/FormHelper/Components/JsonStructureField.vue")
  },
  props: {
    field: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      isCollapsed: false,
      colors: {
        string: "#C3E88D",
        number: "#FF5370",
        boolean: "#C792EA",
        object: "#F78C6C",
        unknown: "#B5B5C3"
      }
    };
  },
  computed: {
    typeColor() {
      let color = "";
      switch (this.field.type) {
        case "string":
          color = this.colors.string;
          break;
        case "number":
          color = this.colors.number;
          break;
        case "boolean":
          color = this.colors.boolean;
          break;
        case "object":
        case "array":
          color = this.colors.object;
          break;
        case "unknown":
          color = this.colors.unknown;
          break;
        default:
          color = "";
          break;
      }
      return "color: " + color + ";";
    }
  },
  methods: {
    copyPath(path) {
      this.$emit("copy-path", path);
    }
  }
};
</script>

<style lang="scss" scoped>
.json-structure-field {
  :deep(.collapse-button) {
    width: 12px;
  }
}
</style>
